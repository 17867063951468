


















































































































































































































































































































































































































































































import Vue from 'vue';
import IMake from './IMake';
import IModel from './IModel';
import ITrim from "./ITrim";
import ISelectableItem from './ISelectableItem';

function FindModel(selectedMake: IMake, modelId: string): IModel | null {
    let result: IModel | null = null;
    selectedMake.Models.forEach(model => {
        if (result == null && model.Id == modelId) {
            result = model;
        }
    });
    return result;
}

export default Vue.extend({
    name: 'Choose',
    data: function () {
        let selectedModelId = this.$route.params.modelId;
        let selectedTrimId = this.$route.params.trimId;
        let m = this.$root.$data as unknown;
        let selectedModel: IModel | null = FindModel(m as IMake, selectedModelId);
        let trimsCollection: Array<ISelectableItem<ITrim>> = [];
        let specCollection: Array<ISelectableItem<string>> = [];
        let optionsCollection: Array<ISelectableItem<string>> = [];
        let pictureUrl: string = "";
        if (selectedModel != null) {
            selectedModel.Trims.forEach((trim, index) => {
                if (index == 0) pictureUrl = trim.ExteriorColors[0].PictureUrl;
                trimsCollection.push({
                    IsSelected: (selectedTrimId == null && index == 0) || (selectedTrimId == trim.Id),
                    Item: trim
                });
            });
            selectedModel.Specifications.forEach(c => {
                specCollection.push({
                    IsSelected: true,
                    Item: c.SectionId
                });
            });
            selectedModel.Options.forEach(o => {
                optionsCollection.push({
                    IsSelected: true,
                    Item: o.SectionId
                });
            });
        }
        //console.log(trimsCollection);

        return {
            selectedModel: selectedModel,
            trimsCollection: trimsCollection,
            specCollection: specCollection,
            optionsCollection: optionsCollection,
            pictureUrl: pictureUrl
        };
    },
    methods: {
        ClickTrim(selectableTrim: ISelectableItem<ITrim>) {
            this.trimsCollection.forEach(item => {
                item.IsSelected = item.Item.Id == selectableTrim.Item.Id;
                if (item.IsSelected) {
                    if (item.Item.ExteriorColors[0].PictureUrl != null) {
                        this.pictureUrl = item.Item.ExteriorColors[0].PictureUrl;
                    }
                    else {
                        this.pictureUrl = "";
                    }
                }
            });
        },

        FormatCurrency(amount: number): string {
            if (!amount) return "Call us!";
            return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        IsSpecificationSectionExpanded(sectionId: string): boolean {
            let found: ISelectableItem<string> | null = null;
            this.specCollection.forEach(c => {
                if (c.Item == sectionId) {
                    found = c;
                }
            });
            if (found == null) return true;
            return (found as ISelectableItem<string>).IsSelected;
        },

        ToggleSectionExpanded(sectionId: string): void {
            let found: ISelectableItem<string> | null = null;
            this.specCollection.forEach(c => {
                if (c.Item == sectionId) {
                    found = c;
                }
            });
            if (found == null) return;
            let foundItem = found as ISelectableItem<string>;
            foundItem.IsSelected = !foundItem.IsSelected;
        },

        IsOptionSectionExpanded(sectionId: string): boolean {
            let found: ISelectableItem<string> | null = null;
            this.optionsCollection.forEach(c => {
                if (c.Item == sectionId) {
                    found = c;
                }
            });
            if (found == null) return true;
            return (found as ISelectableItem<string>).IsSelected;
        },

        ToggleOptionExpanded(sectionId: string): void {
            let found: ISelectableItem<string> | null = null;
            this.optionsCollection.forEach(c => {
                if (c.Item == sectionId) {
                    found = c;
                }
            });
            if (found == null) return;
            let foundItem = found as ISelectableItem<string>;
            foundItem.IsSelected = !foundItem.IsSelected;
        },

        FormatPrice(price: number | null): string {
            if (price == null) return "NA";
            if (price == 0) return "$0";
            return this.FormatCurrency(price);
        },

        IsTrimSelected(trimId: string): boolean {
            let selectedItem: ISelectableItem<ITrim> | null = null;
            this.trimsCollection.forEach(item => {
                if (selectedItem == null && item.IsSelected) {
                    selectedItem = item;
                }
            });
            if (selectedItem == null) return false;
            return selectedItem != null && (selectedItem as ISelectableItem<ITrim>).Item.Id == trimId;
        },

        ClickCustomize() {
            if (this.selectedModel == null) return;
            let selectedItem: ISelectableItem<ITrim> | null = null;
            this.trimsCollection.forEach(item => {
                if (selectedItem == null && item.IsSelected) {
                    selectedItem = item;
                }
            });
            if (selectedItem != null) {
                this.$router.push("/customize/" + this.selectedModel.Id + "/" + (selectedItem as ISelectableItem<ITrim>).Item.Id);
            }
        }
    }
});
