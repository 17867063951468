
















































































import Vue from 'vue';
import IMake from './IMake';
import ICategory from './ICategory';
import IModel from './IModel';

function FindCategory(categoryList: Array<ICategory>, categoryName: string): ICategory | null {
    let result: ICategory | null = null;
    categoryList.forEach(cat => {
        if (result == null && cat.Name == categoryName) {
            result = cat;
        }
    })
    return result;
}

function BuildCategoryList(make: IMake): Array<ICategory> {
    let result: Array<ICategory> = [];
    make.Categories.forEach(catName => {
        result.push({
            Name: catName,
            Models: []
        });
    });

    make.Models.forEach(model => {
        model.Categories.forEach(catName => {
            let cat: ICategory | null = FindCategory(result, catName);
            if (cat != null) {
                cat.Models.push(model);
            }
        });

    });

    return result;
}

export default Vue.extend({
    name: 'App',
    data: function () {
        let m = this.$root.$data as unknown;
        return {
            categories: BuildCategoryList(m as IMake)
        }
    },
    methods: {
        ModelClick(model: IModel) {
            if (!model.DisableCustomize) {
                this.$router.push("/choose/" + model.Id);
            }
        }
    }
});
