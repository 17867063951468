import Vue from 'vue'
import VueRouter, { RouteConfig } from "vue-router";

import App from './App.vue'
import Home from "./components/Home.vue";
import Choose from "./components/Choose.vue";
import Customize from "./components/Customize.vue";
import selectedMake from './data/ford.json';

Vue.config.productionTip = false
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/choose/:modelId/:trimId?",
        name: "choose",
        component: Choose
    },
    {
        path: "/customize/:modelId/:trimId",
        name: "customize",
        component: Customize
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes
});

new Vue({
    router,
    data: selectedMake,
    render: h => h(App)
}).$mount("#app");

//new Vue({
//    render: h => h(App),
//}).$mount('#app')
