


























































































































































































































































































































































































































































































































































import Vue from 'vue';
import IMake from './IMake';
import IModel from './IModel';
import ITrim from "./ITrim";
import CustomizePageModel from './CustomizePageModel';

function FindModel(selectedMake: IMake, modelId: string): IModel | null {
    let result: IModel | null = null;
    selectedMake.Models.forEach(model => {
        if (result == null && model.Id == modelId) {
            result = model;
        }
    });
    return result;
}

function FindTrim(selectedModel: IModel, trimId: string): ITrim | null {
    let result: ITrim | null = null;
    selectedModel.Trims.forEach(trim => {
        if (result == null && trim.Id == trimId) {
            result = trim;
        }
    });
    return result;
}

export default Vue.extend({
    name: 'Customize',
    data: function () {
        let selectedModelId = this.$route.params.modelId;
        let selectedTrimId = this.$route.params.trimId;
        let m = this.$root.$data as unknown;
        let selectedModel: IModel | null = FindModel(m as IMake, selectedModelId);
        if (selectedModel == null) {
            this.$router.push("/");
            return null;
        }
        let selectedTrim: ITrim | null = FindTrim(selectedModel, selectedTrimId);
        if (selectedTrim == null) {
            this.$router.push("/choose/" + selectedModelId);
            return null;
        }
        let model = new CustomizePageModel((m as IMake).RFIEmail, selectedModel, selectedTrim);

        return {
            model: model
        }
    },
    methods: {
        SmartReturn(): void {
            let resultUrl = "/choose/" + this.model.SelectedModel.Id + "/" + this.model.SelectedTrim.Id;
            this.$router.replace(resultUrl);
        },

        FormatCurrency(amount: number): string {
            if (!amount || amount == 0) return "";
            return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        FormattedTotalPrice(): string {
            return this.FormatCurrency(this.model.TotalPrice());
        },

        FormatItemWithPrice(name: string, price: number): string {
            let result: string = name;
            if (price > 0) {
                result += ' (+' + this.FormatCurrency(price) + ')';
            }
            return result;
        },

        Submit(): void {
            alert("Submit confirmed selections.");
        }

    }
});
