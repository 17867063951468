import IContactInfo from "./IContactInfo";
import IExteriorColor from "./IExteriorColor";
import IInteriorColor from "./IInteriorColor";
import IModel from "./IModel";
import IOptionItem from "./IOptionItem";
import IOptionSelections from "./IOptionSelections";
import ISelectableItem from "./ISelectableItem";
import ITrim from "./ITrim";
import axios from 'axios'

export default class CustomizePageModel {
    readonly DefaultRFIEmail: string;
    readonly ContactInfo: IContactInfo = {
        Name: "",
        Phone: "",
        Email: "",
        ValidationMessage: ""
    }
    readonly SelectedModel: IModel;
    readonly SelectedTrim: ITrim;
    readonly ExteriorColors: Array<ISelectableItem<IExteriorColor>> = [];
    readonly InteriorColors: Array<ISelectableItem<IInteriorColor>> = [];
    readonly OptionGroups: Array<IOptionSelections> = [];
    readonly SelectedOptions: Array<IOptionItem> = [];
    SelectedExteriorColor: IExteriorColor = {
        Name: "unknown",
        Price: 0,
        SwatchUrl: "",
        PictureUrl: ""
    };
    SelectedInteriorColor: IInteriorColor = {
        Name: "unknown",
        SwatchUrl: ""
    };

    constructor(defaultRFIEmail: string, selectedModel: IModel, selectedTrim: ITrim) {
        this.DefaultRFIEmail = defaultRFIEmail;
        this.SelectedModel = selectedModel;
        this.SelectedTrim = selectedTrim;

        selectedTrim.ExteriorColors.forEach((c, index) => {
            this.ExteriorColors.push({
                IsSelected: index == 0,
                Item: c
            });
        });
        this.RefreshExteriorColor();

        selectedTrim.InteriorColors.forEach((c, index) => {
            this.InteriorColors.push({
                IsSelected: index == 0,
                Item: c
            });
        });
        this.RefreshInteriorColor();

        this.SelectedTrim.OptionCategories.forEach(c => {
            const optionItems: Array<ISelectableItem<IOptionItem>> = [];
            c.Items.forEach(o => {
                if (o.AutoSelect) {
                    this.SelectedOptions.push(o);
                }
                optionItems.push({
                    IsSelected: o.AutoSelect,
                    Item: o
                });
            });
            this.OptionGroups.push({
                GroupName: c.Name,
                Items: optionItems
            });
        });
    }

    public TotalPrice(): number {
        let result = 0;
        result += this.SelectedTrim.BasePrice;
        result += this.SelectedExteriorColor.Price;
        result += this.SelectedTrim.DestinationCharge;
        this.SelectedOptions.forEach(opt => {
            result += opt.Price;
        });
        return result;
    }

    public ResetSelections(): void {
        this.ExteriorColors.forEach((c, index) => {
            c.IsSelected = index == 0;
        });
        this.RefreshExteriorColor();
        this.InteriorColors.forEach((c, index) => {
            c.IsSelected = index == 0;
        });
        this.RefreshInteriorColor();
        this.OptionGroups.forEach(g => {
            g.Items.forEach(o => {
                o.IsSelected = o.Item.AutoSelect;
            });
        });
        this.RefreshSelectedOptions();
    }

    public SelectExteriorColor(selectedItem: ISelectableItem<IExteriorColor>): void {
        this.ExteriorColors.forEach(item => {
            item.IsSelected = item.Item.Name == selectedItem.Item.Name;
        });
        this.RefreshExteriorColor();
    }

    public SelectInteriorColor(selectedItem: ISelectableItem<IInteriorColor>): void {
        this.InteriorColors.forEach(item => {
            item.IsSelected = item.Item.Name == selectedItem.Item.Name;
        });
        this.RefreshInteriorColor();
    }

    public ToggleOption(opt: ISelectableItem<IOptionItem>): void {
        opt.IsSelected = !opt.IsSelected;
        this.RefreshSelectedOptions();
    }

    private IsExteriorColorSelected(item: ISelectableItem<IExteriorColor>): boolean {
        return item.IsSelected;
    }

    private RefreshExteriorColor(): void {
        const found = this.ExteriorColors.find(this.IsExteriorColorSelected);
        if (found != undefined) {
            this.SelectedExteriorColor = found.Item;
        }
    }

    public SendInfoRequest(): void {
        this.ContactInfo.ValidationMessage = "";

        if (this.ContactInfo.Name == null || this.ContactInfo.Name.trim() == "") {
            this.ContactInfo.ValidationMessage += "Name is required.";
        }

        if (this.ContactInfo.Phone == null || this.ContactInfo.Phone.trim() == "") {
            if (this.ContactInfo.ValidationMessage != "") this.ContactInfo.ValidationMessage += "  ";
            this.ContactInfo.ValidationMessage += "Phone number is required.";
        }

        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.ContactInfo.Email == null || !regexp.test(this.ContactInfo.Email)) {
            if (this.ContactInfo.ValidationMessage != "") this.ContactInfo.ValidationMessage += "  ";
            this.ContactInfo.ValidationMessage += "Valid e-mail address is required.";
        }
        if (this.ContactInfo.ValidationMessage != "") return

        //Send e-mail here
        //const data =
        //{
        //    to_email: this.DefaultRFIEmail,
        //    from_email: this.ContactInfo.Email,
        //    subject: "RFI:  " + this.SelectedTrim.Description,
        //    message: this.CreateEmailBody()
        //};
        const form = new FormData();
        form.append('to_email', this.DefaultRFIEmail);
        form.append('from_email', this.ContactInfo.Email);
        form.append('subject', "RFI:  " + this.SelectedTrim.Description);
        form.append('message', this.CreateEmailBody())
        const obj: IContactInfo = this.ContactInfo;
        axios
            //.post("http://localhost:48343/MailHandler.php", form)
            .post("/MailHandler.php",form)
            .then(result =>
            {
                console.log(result.data);
                obj.ValidationMessage = result.data;// "Your request was sent successfully.";
            });
        
        //    if (this.SelectedModel.RFIEmail != null && this.SelectedModel.RFIEmail.trim() != '') targetEmail = this.SelectedModel.RFIEmail;
        //    let result: string = "mailto:" + targetEmail;
        //    result += "?subject=RFI:  " + this.SelectedTrim.Description;
        //    result += "&body=" + this.CreateEmailBody();
        //    location.href = result;
    }

    private CreateEmailBody(): string {
        let result: string = 'Name:  ' + this.ContactInfo.Name.trim() + '\n';
        result += 'Phone:  ' + this.ContactInfo.Phone.trim() + '\n\n';
        result += 'E-Mail:  ' + this.ContactInfo.Email.trim() + '\n\n';
        result += 'Please contact me about:  \n\n';
        result += this.SelectedTrim.Description + '\n\n';
        result += 'Requested Options:\n'
        result += '   Exterior color:  ' + this.SelectedExteriorColor.Name + "\n";
        result += '   Interior color:  ' + this.SelectedInteriorColor.Name + "\n\n";
        this.SelectedOptions.forEach(opt => {
            result += '   ' + opt.Name + "\n";
        });
        return result;
        //return encodeURIComponent(result);
    }

    private IsInteriorColorSelected(item: ISelectableItem<IInteriorColor>): boolean {
        return item.IsSelected;
    }

    private RefreshInteriorColor(): void {
        const found = this.InteriorColors.find(this.IsInteriorColorSelected);
        if (found != undefined) {
            this.SelectedInteriorColor = found.Item;
        }
    }

    private RefreshSelectedOptions(): void {
        this.SelectedOptions.length = 0;
        this.OptionGroups.forEach(grp => {
            grp.Items.forEach(opt => {
                if (opt.IsSelected) {
                    this.SelectedOptions.push(opt.Item);
                }
            });
        })
    }

}